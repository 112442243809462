export const URL_COMPANIES = "/companies";
const DATA_ROWS = "/datarows";
const DOCUMENTS = "/documents";
const DOCUMENT_MODULES = "/modules";
const INCIDENTS = "/incidents";
const NOTIFICATIONS = "/notifications";
const SKILLS = "/skills";
const LINKS = "/links";
const POSITION_LEVELS = "/position-levels";
const TODOS = "/todos";
const USERS = "/users";
const TEMPLATES = "/templates";


const getCompany = (companyId: string) => `${URL_COMPANIES}/${companyId}`;
const getCompanyLink = (companyId: string) => `${LINKS}/${companyId}`;
const getDataRow = (dataRowId: string) => `${DATA_ROWS}/${dataRowId}`;
const getDocument = (documentId: string) => `${DOCUMENTS}/${documentId}`;
const getDocumentModule = (moduleId: string) => `${DOCUMENT_MODULES}/${moduleId}`;
const getIncident = (incidentId: string) => `${INCIDENTS}/${incidentId}`;
const getNotification = (notificationId: string) => `${NOTIFICATIONS}/${notificationId}`;
const getSkill = (skillId: string) => `${SKILLS}/${skillId}`;
const getPositionLevel = (positionLevelId: string) => `${POSITION_LEVELS}/${positionLevelId}`;
const getUser = (userId: string) => `${USERS}/${userId}`;
const getTemplate = (templateId: string) => `${TEMPLATES}/${templateId}`;

export const URL_AUTHENTICATE = "/users/login";

// COMPANIES
// export const URL_COMPANIES = COMPANIES;
export const URL_COMPANY_LOGO = (companyId: string) => getCompany(companyId) + "/logo";
export const URL_COMPANY_TOGGLE_ACTIVE = (companyId: string) => getCompany(companyId) + "/toggle-active";
export const URL_COMPANY_PARENTS = () => `${URL_COMPANIES}/parents`;
export const URL_COMPANY_UPDATE = (companyId: string) => getCompany(companyId) + "/update";

// DOCUMENTS
export const URL_DOCUMENT_DOWNLOAD = (documentId: string) => getDocument(documentId) + "/download?fileFormat=pdf";
export const URL_DOCUMENT_CREATE = (companyId: string) => getCompany(companyId) + "/documents";
export const URL_DOCUMENT = (documentId: string) => getDocument(documentId);
export const URL_DOCUMENT_DELETE = (documentId: string) => getDocument(documentId);
export const URL_DOCUMENT_UPLOAD = (companyId: string) => getCompany(companyId) + `${DOCUMENTS}/upload`;
export const URL_DOCUMENT_COPY = (documentId: string) => getDocument(documentId) + "/copy";
export const URL_DOCUMENT_IMPORTANT = (companyId: string, documentId: string) => getCompany(companyId) + getDocument(documentId) + "/important";
export const URL_DOCUMENT_IMPORTANT_HANDLED = (documentId: string) => getDocument(documentId) + "/important/handled";
export const URL_DOCUMENT_ATTACHMENT_UPLOAD = (documentId: string) => getDocument(documentId) + "/attachments";
export const URL_DOCUMENT_ATTACHMENT_DOWNLOAD = (documentId: string, attachmentId: string) => getDocument(documentId) + `/attachments/${attachmentId}/download`;
export const URL_DOCUMENT_ATTACHMENT_DELETE = (documentId: string, attachmentId: string) => getDocument(documentId) + `/attachments/${attachmentId}`;
export const URL_DOCUMENT_DOWNLOAD_PDF = (documentId: string, orientation: 'landscape' | 'portrait') => getDocument(documentId) + "/pdf?orientation=" + orientation;
export const URL_DOCUMENT_UPDATE_VERSION = (documentId: string) => getDocument(documentId) + "/version";
export const URL_DOCUMENT_GET_VERSIONS = (documentId: string) => getDocument(documentId) + "/versions";
export const URL_DOCUMENT_RESTORE = (companyId: string, documentId: string) => getCompany(companyId) + getDocument(documentId) + "/restore";
export const URL_DOCUMENT_CLOSE = (documentId: string) => getDocument(documentId) + "/close";
export const URL_DOCUMENT_UNLOCK = (documentId: string) => getDocument(documentId) + "/unlock";


export const URL_DOCUMENT_COMPANY_READ_RECEIPT = (companyId: string) => getCompany(companyId) + "/read-receipt";
export const URL_DOCUMENT_GET_READ_RECEIPT = (companyId: string, documentId: string) => getCompany(companyId) + getDocument(documentId) + "/read-receipt";
export const URL_USER_COMPANY_READ_RECEIPT = (companyId: string) => getCompany(companyId) + "/read-receipt/user";
export const URL_DOCUMENT_CREATE_READ_RECEIPT = (companyId: string, documentId: string) => getCompany(companyId) + getDocument(documentId) + `/read-receipt`;
export const URL_DOCUMENT_MARK_READ_RECEIPT = (companyId: string, documentId: string) => getCompany(companyId) + getDocument(documentId) + "/read-receipt";


export const URL_DOCUMENT_PUBLISH_REQUEST_APPROVAL = (documentId: string) => getDocument(documentId) + "/publish/request-approval";
export const URL_DOCUMENT_PUBLISH_APPROVE = (documentId: string) => getDocument(documentId) + "/publish/approve";
export const URL_DOCUMENT_PUBLISH_REJECT = (documentId: string) => getDocument(documentId) + "/publish/reject";
export const URL_DOCUMENT_PUBLISH_USER = (companyId: string) => getCompany(companyId) + "/publish/user";

// DOCUMENT MODULES
export const URL_DOCUMENT_MODULES = (documentId: string) => getDocument(documentId) + "/modules";

// DOCUMENT MODULE CHECKLIST
export const URL_CHECKLIST_FORM = (documentId: string, moduleId: string) => getDocument(documentId) + getDocumentModule(moduleId) + "/checklist/form";
export const URL_DELETE_CHECKLIST_FORM = (documentId: string, moduleId: string) => getDocument(documentId) + getDocumentModule(moduleId) + "/checklist/form";
export const URL_ANSWER_CHECKLIST = (documentId: string, moduleId: string) => getDocument(documentId) + getDocumentModule(moduleId);
export const URL_MULTI_CHOICE_OPTIONS = () => DATA_ROWS + "/multichoices";
export const URL_DATA_ROW_DOWNLOAD_IMAGE = (documentId: string, moduleId: string, formId: string, dataRowId: string) => URL_CHECKLIST_FORM(documentId, moduleId) + `/${formId}${getDataRow(dataRowId)}/download`;
export const URL_DATA_ROW_FILE = (documentId: string, dataRowFileId: string) => getDocument(documentId) + `/download/file/${dataRowFileId}`;

// TEMPLATES
export const URL_GET_TEMPLATES_BY_TYPE = (companyId: string | undefined, templateType: string, onlyApproved: boolean) => (companyId ? getCompany(companyId) : "") + `${TEMPLATES}?templateType=${templateType}&onlyApproved=${onlyApproved}`;
export const URL_CREATE_DOCUMENT_FROM_TEMPLATE = (companyId: string, templateId: string) => getCompany(companyId) + getTemplate(templateId) + "/document";
export const URL_CREATE_DOCUMENT_TEMPLATE = (companyId?: string) => (companyId ? getCompany(companyId) : "") + TEMPLATES;
export const URL_CREATE_TEMPLATE_FOLDER = (companyId?: string) => (companyId ? getCompany(companyId) : "") + `${TEMPLATES}/folder`;
export const URL_CREATE_CHECKLIST_TEMPLATE = (companyId: string, moduleId: string) => getCompany(companyId) + `/checklist/${moduleId}/templates`;
export const URL_MOVE_TEMPLATES = () => `${TEMPLATES}/move`;
export const URL_RENAME_TEMPLATE = () => `${TEMPLATES}/rename`;
export const URL_TEMPLATE_DELETE = (templateId: string, nodeType: string) => getTemplate(templateId) + `?nodeType=${nodeType}`;
export const URL_PUBLISH_TEMPLATE_DOCUMENT = (documentId: string) => `${TEMPLATES}/publish/${documentId}`;
export const URL_PUBLISH_ALL_TEMPLATE_DOCUMENT = (folderId: string) => `${TEMPLATES}/publish-all/${folderId}`;
export const URL_TEMPLATE_SET_PRIVATE = (templateId: string) => getTemplate(templateId) + "/private";
export const URL_TEMPLATE_COPY = (companyId?: string) => (companyId ? getCompany(companyId) : "") + `${TEMPLATES}/copy`;

export const URL_CREATE_CHECKLIST_FROM_TEMPLATE = (companyId: string, documentId: string, templateId: string) => getCompany(companyId) + getDocument(documentId) + `/templates/${templateId}/checklist`;

//ActionPlans
export const URL_GET_ACTION_PLAN = (companyId: string) => getCompany(companyId) + "/action-plan";

export const URL_GET_ACTION_PLAN_BY_ID = (companyId: string, actionPlanId: string) => getCompany(companyId) + `/action-plan/${actionPlanId}`;
export const URL_SET_ACTION_PLAN_ON_GOING = (companyId: string, actionPlanId: string) => getCompany(companyId) + `/action-plan/${actionPlanId}/ongoing`;
export const URL_UPDATE_ACTION_PLAN = (companyId: string, actionPlanId: string) => getCompany(companyId) + `/action-plan/${actionPlanId}`;
export const URL_ACTION_PLAN_DELETE = (companyId: string, actionPlanId: string) => getCompany(companyId) + `/action-plan/${actionPlanId}`;

export const URL_CREATE_ACTION_FROM_INCIDENT = (companyId: string) => getCompany(companyId) + `/action-plan/incident`;
export const URL_CREATE_ACTION_FROM_STANDALONE = (companyId: string) => getCompany(companyId) + `/action-plan/standalone`;
export const URL_ACTION_PLAN_DOWNLOAD_IMAGE = (actionPlanId: string, imageVersion: string) => `/action-plan/${actionPlanId}/download-image/${imageVersion}`;
export const URL_ACTION_PLAN_DOWNLOAD_FILE = (actionPlanId: string, dataRowId: string) => `/action-plan/${actionPlanId}/download-file/${dataRowId}`;
export const URL_UPDATE_ACTIONPLAN_DATAROW = (companyId: string, actionPlanId: string) => getCompany(companyId) + `/action-plan/${actionPlanId}/datarows/update`;
export const URL_ACTION_PLAN_DOWNLOAD_PDF = (companyId: string, actionPlanId: string, orientation: 'landscape' | 'portrait') => getCompany(companyId) + `/action-plan/${actionPlanId}/pdf?orientation=${orientation}`;

// FOLDERS
export const URL_FOLDERS = (companyId: string) => getCompany(companyId) + "/folders";
export const URL_FOLDERS_MOVE = (companyId: string) => getCompany(companyId) + "/folders/move";
export const URL_FOLDERS_DELETE = (companyId: string, folderId: string) => getCompany(companyId) + `/folders/${folderId}`;
export const URL_DOCUMENT_NODES = (companyId: string) => getCompany(companyId) + "/documentNodes";
export const URL_RENAME_DOCUMENT_NODE = (companyId: string) => URL_DOCUMENT_NODES(companyId) + "/renameNode";
export const URL_DOCUMENT_NODES_SORT = (companyId: string) => URL_DOCUMENT_NODES(companyId) + "/sort";
export const URL_FOLDER_RESTORE = (companyId: string, folderId: string) => getCompany(companyId) + `/folders/${folderId}/restore`;
export const URL_DOCUMENT_MOVE = (companyId: string) => getCompany(companyId) + "/move/documents"; //Borde ändra denna endpointen, kör på detta nu iom att jag inte fått react-query att funka med dynamisk url.
export const URL_FOLDER_APPROVE_ALL_DOCUMENTS = (folderId: string) => `/companies/folders/${folderId}/approveAll`;
// FIXME uncomment when BE is fixed
// export const URL_FAVORITE_DOCUMENT_NODES = (companyId: string) => `${COMPANIES}/${companyId}/foldersLight/favorites`;

// INCIDENTS
export const URL_INCIDENTS = (companyId: string) => getCompany(companyId) + INCIDENTS;
export const URL_INCIDENT = (companyId: string, incidentId: string) => getCompany(companyId) + getIncident(incidentId);
export const URL_INCIDENT_DOWNLOAD_FILE = (companyId: string, incidentId: string, incidentFileId: string) => getCompany(companyId) + getIncident(incidentId) + `/${incidentFileId}/download`;
export const URL_INCIDENT_VIEW = '/incident/view';


// POSITIONLEVELS
export const URL_GET_POSITION_LEVELS = (companyId: string) => getCompany(companyId) + POSITION_LEVELS;
export const URL_CREATE_POSITION_LEVEL = (companyId: string) => getCompany(companyId) + POSITION_LEVELS;
export const URL_UPDATE_POSITION_LEVEL = (companyId: string, positionLevelId: string) => getCompany(companyId) + getPositionLevel(positionLevelId);
export const URL_DELETE_POSITION_LEVEL = (companyId: string, positionLevelId: string) => getCompany(companyId) + getPositionLevel(positionLevelId);

// SKILLS
export const URL_COMPANY_SKILLS = (companyId: string) => getCompany(companyId) + SKILLS;
export const URL_CREATE_SKILL = (companyId: string) => getCompany(companyId) + SKILLS;
export const URL_UPDATE_SKILL = (companyId: string, skillId: string) => getCompany(companyId) + getSkill(skillId);
export const URL_DELETE_SKILL = (companyId: string, skillId: string) => getCompany(companyId) + getSkill(skillId);


// USERS
export const URL_COMPANY_USERS = (companyId: string) => getCompany(companyId) + USERS;
export const URL_COMPANY_USERS_EXTENDED = (companyId: string) => getCompany(companyId) + "/users-extended";
export const URL_COMPANY_USER_CHANGE_UPDATE = (companyId: string, userId: string) => getCompany(companyId) + getUser(userId) + "/update";
export const URL_COMPANY_USER_CHANGE_ROLE = (companyId: string, userId: string) => getCompany(companyId) + getUser(userId) + "/role";

export const URL_COMPANY_USER_DOCUMENTS = (companyUserId: string) => `/users/${companyUserId}/documents`;
export const URL_COMPANY_USER_DELETE = (companyId: string, userId: string) => getCompany(companyId) + getUser(userId);
export const URL_COMPANY_USER_UPLOAD_ATTACHMENT = (companyId: string, companyUserId: string) => getCompany(companyId) + "/company-user/" + companyUserId + "/attachments";
export const URL_COMPANY_USER_DOWNLOAD_ATTACHMENT = (companyId: string, companyUserId: string, attachmentId: string) => getCompany(companyId) + "/company-user/" + companyUserId + "/attachments/" + attachmentId;
export const URL_COMPANY_USER_VIEW_DOCUMENT = (companyId: string, documentId: string) => getCompany(companyId) + `/viewed/${documentId}`;
export const URL_COMPANY_USER_LAST_DOC = (companyId: string) => getCompany(companyId) + "/viewed";
export const URL_NOTIFICATIONS = () => NOTIFICATIONS;
export const URL_NOTIFICATIONS_UNREAD = () => NOTIFICATIONS + "/unread";

export const URL_NOTIFICATIONS_READ = () => NOTIFICATIONS + "/read";

export const URL_EMPLOYMENT_TYPE = () => `users/employmentType`;
export const URL_NOTIFICATIONS_DELETE = (notificationId: string) => getNotification(notificationId);

export const URL_COMPANYUSER_TODO = (companyId: string) => getCompany(companyId) + TODOS;

export const URL_USER_CHANGE_SYSTEMROLE = (userId: string) => getUser(userId) + "/role";

export const URL_COMPANY_USER_SKILLS = (companyId: string, companyUserId: string) => getCompany(companyId) + (companyUserId ? getUser(companyUserId) + SKILLS : '/user-skills');
export const URL_COMPANY_USER_SKILL_CREATE = (companyId: string, companyUserId: string) => getCompany(companyId) + getUser(companyUserId) + SKILLS;
export const URL_COMPANY_USER_SKILL_UPDATE = (companyId: string, companyUserId: string, userSkillId: string) => getCompany(companyId) + getUser(companyUserId) + getSkill(userSkillId);
export const URL_COMPANY_USER_SKILL_DELETE = (companyId: string, companyUserId: string, userSkillId: string) => getCompany(companyId) + getUser(companyUserId) + getSkill(userSkillId);
export const URL_COMPANY_USER_SKILL_DOWNLOAD_FILE = (companyId: string, companyUserId: string, userSkillId: string, dataRowId: string) => getCompany(companyId) + getUser(companyUserId) + getSkill(userSkillId) + `/download-file/${dataRowId}`;

export const URL_COMPANY_LINKS = (companyId: string) => getCompany(companyId) + LINKS;
export const URL_CREATE_COMPANY_LINK = (companyId: string) => getCompany(companyId) + LINKS;
export const URL_DELETE_COMPANY_LINK = (companyId: string, companyLinkId: string) => getCompany(companyId) + getCompanyLink(companyLinkId);