import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteCompanyLink } from "../../../../api/CompanyApi";
import ModalForm from "../../../../components/FormComponents/ModalForm";
import { ICompanyLink } from "../../../../core/interfaces/company";


const RemoveCompanyLinkModal: React.FC<{
    show: boolean,
    hide: () => void,
    companyLink: ICompanyLink | null
}> = ({
    show,
    hide,
    companyLink
}) => {
        const { t } = useTranslation();
        const [remove, isRemoving] = useDeleteCompanyLink(companyLink?.id ?? '');
        if (companyLink == null)
            return <></>

        return (
            <ModalForm
                show={show}
                hide={hide}
                title={t('RemoveCompanyLinkModal.REMOVE_COMPANY_LINK_1')}
                isEdited={() => false}
                submit={() => remove().then(_ => hide())}
                submitText={t('RemoveCompanyLinkModal.YES_1')}
                isLoading={isRemoving}>
                <p>{t('RemoveCompanyLinkModal.CONFIRM_DELETE_1')} '{companyLink.name}'?</p>
            </ModalForm>
        )
    }

export default RemoveCompanyLinkModal