import { CompanyType, LinkType } from "../enums/enums";
import { ICompanyLink, ICreateCompany } from "../interfaces/company";

const createNewCompany = (parentCompanyId: string | null): ICreateCompany => {
    return { city: "", createdAt: "", logo: "", createdById: "", headings: [], id: "", name: "", orgNo: "", isActive: true, featureSkills: false, companyType: CompanyType.COMPANY, parentCompanyId: parentCompanyId }
}

const createNewCompanyLink = (): ICompanyLink => {
    return { id: "", name: "", linkType: LinkType.EXTERNAL, companyId: "" }
}

const validateOrgNo = (orgNo: string): boolean => {
    return /^[0-9]{6}-[0-9]{4}$/.test(orgNo);
}

export { createNewCompany, createNewCompanyLink, validateOrgNo };