import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import When from "../../components/When";
import { ActionPlanType, CompanyRole } from "../../core/enums/enums";
import { displayGreeting } from "../../core/helpers/greetingHelper";
import { IDocument } from "../../core/interfaces/document";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useAppSelector } from "../../store/hooks";
import DashboardCompanyLinks from "./Components/CompanyLink/DashboardCompanyLinks";
import NewIncidentModal from "./Components/Incident/NewIncidentModal";
import LastDocumentTable from "./Components/LastDocumentTable";
import DashboardUnHandledReadReceipts from "./Components/ReadReceipt/DashboardUnHandledReadReceipts";
import DashboardTodo from "./Components/Todo/DashboardTodo";
import DashboardCard from "./DashboardCard";
import { DashboardCardContentImage } from "./DashboardCardContent";


const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user);

    const { t } = useTranslation();
    const [showReport, setShowReport] = useState(false);
    const [checkAccess] = useAccessCheck();

    const dashboardContent = () => (
        <>
            <Col lg={3} className="pb-3">
                <Row>
                    <Col className="mt-3">
                        <DashboardTodo />
                    </Col>
                </Row>
            </Col>

            <Col lg={9} className="pb-3">
                <Row>
                    <Col md={4}>
                        <Row className="mb-3 mt-3">
                            <Col>
                                <When condition={checkAccess(CompanyRole.ADMIN)}
                                    otherwise={<DashboardCard fetching={false} theme={'lightblue'} />}>
                                    <DashboardUnHandledReadReceipts />
                                </When>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {user.parentCompanyId ? (
                                    <DashboardCard fetching={false} theme={"lightblue"} height="12rem" onClick={() => navigate("/c")}>
                                        <DashboardCardContentImage
                                            image={<i className={getIcon(Icon.CUSTOMERS, IconSize.X5)}
                                                title={t('Dashboard.CONNECTED_COMPANIES_1')}></i>}
                                            title={t('Dashboard.CONNECTED_COMPANIES_2')}
                                        />
                                    </DashboardCard>
                                ) : (
                                    <DashboardCard fetching={false} theme={"lightblue"} height="12rem">
                                    </DashboardCard>
                                )}
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4}>
                        <Row className="mb-3 mt-3">
                            <Col>
                                {user.companyUserId ? (
                                    <DashboardCard fetching={false} theme={"green"} height="12rem" onClick={() => navigate(`/c/${user.companyId}/users/${user.companyUserId}/documents`)}>
                                        <DashboardCardContentImage
                                            image={<i className={getIcon(Icon.FOLDER_OPEN, IconSize.X5)}
                                                title={t('Dashboard.DOCUMENTS_1')}></i>}
                                            title={t('Dashboard.DOCUMENTS_2')}
                                        />
                                    </DashboardCard>
                                ) : (
                                    <DashboardCard fetching={false} theme={"green"} height="12rem">
                                    </DashboardCard>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DashboardCard fetching={false} theme={"green"} height="12rem" onClick={() => navigate("documents#root")}>
                                    <DashboardCardContentImage
                                        image={<i className={getIcon(Icon.CLIPBOARD, IconSize.X5)}
                                            title={t('Dashboard.COMPANY_DOCUMENTS_1')}></i>}
                                        title={t('Dashboard.COMPANY_DOCUMENTS_2')}
                                    />
                                </DashboardCard>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4}>
                        <Row className="mb-3 mt-3">
                            <Col>
                                <DashboardCard fetching={false} theme={"darkblue"} height="12rem"
                                    onClick={() => setShowReport(true)}>
                                    <DashboardCardContentImage
                                        image={<i className={getIcon(Icon.INCIDENT, IconSize.X5)}
                                            title={t('Dashboard.REPORT_INCIDENT_ACCIDENT_DEVIATION_1')}></i>}
                                        title={t('Dashboard.REPORT_INCIDENT_ACCIDENT_DEVIATION_2')}
                                    />
                                </DashboardCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DashboardCard fetching={false} theme={"darkblue"} height="12rem"
                                    onClick={() => navigate("action-plan")}>
                                    <DashboardCardContentImage
                                        image={<i className={getIcon(Icon.CALENDAR, IconSize.X5)}
                                            title={t('Dashboard.ACTION_PLAN_1')}></i>}
                                        title={t('Dashboard.ACTION_PLAN_2')}
                                    />
                                </DashboardCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </>
    );

    const columns: TableColumn<IDocument>[] = [
        {
            name: t('Dashboard.DOCUMENT_1'),
            cell: cell => <Link to={"documents/" + cell.id}>{cell.name}</Link>,
            sortable: true,
        }
    ];

    const renderView = () => (
        <MainContainer>
            <HeaderContainer title={displayGreeting(user.firstName)} />
            <Row className="mb-4">
                {dashboardContent()}
            </Row>
            <Row>
                <Col lg={3}>
                    <DashboardCompanyLinks />
                </Col>
                <Col lg={9}>
                    <HeaderContainer title={t('Dashboard.LATEST_DOCUMENTS_1')} />
                    <LastDocumentTable />
                </Col>
            </Row>
            <NewIncidentModal
                initialActionPlanType={ActionPlanType.INCIDENT}
                show={showReport}
                setShow={() => setShowReport(false)}
            />
        </MainContainer>
    )

    return renderView();
}

export default Dashboard;