import { useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetCompanyLinks } from "../../../../api/CompanyApi";
import getIcon, { Icon, IconSize, getRegularIcon } from "../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../components/ClickableIconButton/ClickableIconButton";
import CustomDropdown, { DropdownToggleType } from "../../../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import DataTableNoData from "../../../../components/DataTableComponents/DataTableNoData";
import When from "../../../../components/When";
import { CompanyRole, LinkType } from "../../../../core/enums/enums";
import { ICompanyLinkResponse } from "../../../../core/interfaces/company";
import useAccessCheck from "../../../../hooks/useAccessCheck";
import { goToDocument } from "../../../DocumentDirectory/Helpers/DirectoryHelper";
import styles from "./DashboardCompanyLinks.module.scss";
import NewCompanyLinkModal from "./NewCompanyLinkModal";
import RemoveCompanyLinkModal from "./RemoveCompanyLinkModal";

const DashboardCompanyLinks = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [checkCompanyRole] = useAccessCheck();

    const [companyLinks, isFetching] = useGetCompanyLinks();
    
    const [openNewCompanyLinkModal, setOpenNewCompanyLinkModal] = useState(false);
    const [showRemoveCompanyLinkModal, setShowRemoveCompanyLinkModal] = useState(false);
    const [selectedCompanyLink, setSelectedCompanyLink] = useState<ICompanyLinkResponse | null>(null);

    const handleClick = (e: any, link: ICompanyLinkResponse) => {
        e.preventDefault();
        e.stopPropagation();

        if (link.linkType === LinkType.DOCUMENT) {
            if (link.documentType === 'File') {
                goToDocument(link.documentId!);
            } else {
                navigate(`documents/${link.documentId}`);
            }
        } else {
            window.open(link.url!);
        }
    }

    const LinkCard = ({ link }: { link: ICompanyLinkResponse }) => (
        <Card className={link.linkType === LinkType.DOCUMENT ? styles.docCard : styles.card}>
            <Card.Body className={styles.body}>
                <div className={"d-flex justify-content-between align-items-center me-3"}>
                    <div className={"d-flex align-items-center w-100"} onClick={(e: any) => handleClick(e, link)}>
                        <i className={(link.linkType === LinkType.DOCUMENT ? getRegularIcon(Icon.FILE_LINES, IconSize.LARGE) : getIcon(Icon.UP_RIGHT_ARROW, IconSize.LARGE)) + " m-3"} />
                        <div>
                            <Card.Text className={"mb-0"}>{link.name}</Card.Text>
                            <Card.Text className={styles.url}>{link.url ?? t('DashboardCompanyLinks.INTERNAL_DOCUMENT_1')}</Card.Text>
                        </div>
                    </div>
                    {checkCompanyRole(CompanyRole.ADMIN) && (
                        <CustomDropdown toggleType={DropdownToggleType.DOTS}>
                            <CustomDropdownItem
                                icon={Icon.REMOVE}
                                text={t('DashboardCompanyLinks.REMOVE_COMPANY_LINK_1')}
                                onClick={() => {
                                    setSelectedCompanyLink(link);
                                    setShowRemoveCompanyLinkModal(true);
                                }}
                            />
                        </CustomDropdown>
                    )}
                </div>
            </Card.Body>
        </Card>
    );

    return (
        <>
            <Row className={"mb-2"}>
                <Col>
                    <div className={"d-flex justify-content-between align-items-center ms-3 me-1"}>
                        <h5 className="mb-0">{t('DashboardCompanyLinks.LINKS_1')}</h5>
                        {checkCompanyRole(CompanyRole.ADMIN) && (
                            <ClickableIconButton
                                disabled={false}
                                buttonType={"EDIT"}
                                icon={getIcon(Icon.CIRCLE_PLUS, IconSize.XL)}
                                onClick={() => setOpenNewCompanyLinkModal(true)}
                                title={t('DashboardCompanyLinks.ACTION_1')}
                            />
                        )}
                    </div>
                </Col>
            </Row>

            <When condition={!isFetching} otherwise={<Spinner animation={"border"} />}>
                <When condition={companyLinks.length > 0} otherwise={<DataTableNoData children={t('DashboardCompanyLinks.NO_LINKS_1')} />}>
                    {companyLinks.map((link) => (
                        <LinkCard key={link.id} link={link} />
                    ))}
                </When>
            </When>

            <NewCompanyLinkModal
                show={openNewCompanyLinkModal}
                hide={() => setOpenNewCompanyLinkModal(false)}
            />

            <RemoveCompanyLinkModal
                show={showRemoveCompanyLinkModal}
                hide={() => setShowRemoveCompanyLinkModal(false)}
                companyLink={selectedCompanyLink}
            />
        </>
    );
}

export default DashboardCompanyLinks
