import { NodeModel } from "@minoru/react-dnd-treeview";
import { useQueryClient } from "@tanstack/react-query";
import _ from 'lodash';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateCompanyLink } from "../../../../api/CompanyApi";
import { useGetNodes } from "../../../../api/DocumentDirectory";
import FormBase from "../../../../components/FormComponents/FormBase";
import GenericInput from "../../../../components/FormComponents/GenericInput";
import ModalForm from "../../../../components/FormComponents/ModalForm";
import When from "../../../../components/When";
import { CustomFolders, LinkType } from "../../../../core/enums/enums";
import { createNewCompanyLink } from "../../../../core/helpers/companyHelper";
import { toastSuccess } from "../../../../core/helpers/toastHelper";
import { ICompanyLink, ICompanyLinkResponse } from "../../../../core/interfaces/company";
import { IDocumentNodeModel, IFolderNodeModel } from "../../../../core/interfaces/directory";
import { AppQueryKey } from "../../../../core/queryKeys";
import useUpdatableObject from "../../../../hooks/useUpdatableObject";
import { addToast } from "../../../../store/slices/uiSlice";
import styles from "../../../Document/Components/Templates/SaveAsTemplate.module.scss";
import DirectoryLite from "../../../DocumentDirectory/DirectoryLite";
import { convertDirectoryNodesToModels, isFolder } from "../../../DocumentDirectory/Helpers/DirectoryHelper";


const NewCompanyLinkModal: React.FC<{ show: boolean, hide: () => void }> = ({ show, hide }) => {
    const { t } = useTranslation();
    const { cId } = useParams();
    const dispatch = useDispatch();
    const useQuery = useQueryClient();

    const [updated, setUpdated, isEdited, submit, loading] = useUpdatableObject<ICompanyLink, ICompanyLinkResponse>(createNewCompanyLink(), (createCompanyLink) => {
        const companyLink = _.cloneDeep(createCompanyLink) as ICompanyLink;
        companyLink.companyId = cId!;
        return CreateCompanyLink(companyLink);
    });

    const [selectedNode, setSelectedNode] = useState<NodeModel<IFolderNodeModel | IDocumentNodeModel> | null>(null);
    const [data, setData] = useState<NodeModel<IFolderNodeModel | IDocumentNodeModel>[]>([]);
    const [expandedFolders, setExpandedFolders] = useState(new Set<string>());

    const {
        isFetching: isFetchingDocumentDirectoryNodes,
        data: documentDirectoryNodes,
    } = useGetNodes(true);

    const [errors, setErrors] = useState({
        name: true,
        url: true,
        documentId: true
    });

    useEffect(() => {
        setErrors({
            name: true,
            url: updated.linkType === LinkType.EXTERNAL,
            documentId: updated.linkType === LinkType.DOCUMENT
        });
        setUpdated({
            ...createNewCompanyLink()
        });
    }, [show, setUpdated])

    useEffect(() => {
        if (documentDirectoryNodes) {
            setData(convertDirectoryNodesToModels(documentDirectoryNodes.data.data)
                .filter(item =>
                    item.id !== CustomFolders.TRASHBIN_PREFIXED &&
                    item.id !== CustomFolders.COMPLETEDBIN_PREFIXED));
        }
    }, [documentDirectoryNodes]);


    const validate = (companyLink: ICompanyLink, name: string) => {
        switch (name) {
            case 'name':
                errors.name = !companyLink.name.length;
                break;
            case 'url':
                errors.url = !companyLink.url?.length;
                errors.documentId = false;
                break;
            case 'documentId':
                errors.documentId = !companyLink.documentId;
                errors.url = false;
                break;
        }
        setErrors(errors);
        return companyLink;
    }

    const commonProps = {
        isEditMode: true,
        object: updated,
        errorObject: errors,
        setValue: setUpdated,
        onChangeMiddleWare: validate,
    }

    const handleSubmit = () => {
        submit().then(createdCompanyLink => {
            onHide();
            useQuery.invalidateQueries([AppQueryKey.COMPANY_LINKS]);
            dispatch(addToast(toastSuccess(t('NewCompanyLinkModal.SUCCESS_1'), `${t('NewCompanyLinkModal.LINK_1')} ${createdCompanyLink.name} ${t('NewCompanyLinkModal.CREATED_1')}`)));
        });
    }


    const handleSetSelectedNode = (node: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => {
        if (isFolder(node))
            return;

        setSelectedNode(node);
        setUpdated({
            ...updated,
            name: node.text.toString(),
            documentId: node.data?.id.toString()
        });
        setErrors({
            ...errors,
            name: false,
            url: false,
            documentId: false
        });
    }

    const handleChangeLinkType = (e: any) => {
        setUpdated({
            ...updated,
            linkType: e.target.value,
            name: '',
            url: undefined,
            documentId: undefined
        });
        setErrors({
            ...errors,
            name: true,
            url: true,
            documentId: false
        });
    }

    const handleExpandedFoldersToggle = (id: string) => {
        if (!expandedFolders.has(id)) {
            setExpandedFolders(new Set([...expandedFolders, id]));
        } else {
            setExpandedFolders(prevStrings => {
                const newSet = new Set(prevStrings);
                newSet.delete(id);
                return newSet;
            });
        }
    }

    const onHide = () => {
        setSelectedNode(null);
        setExpandedFolders(new Set<string>());
        hide();
    }

    return (
        <ModalForm
            show={show}
            hide={onHide}
            size={'lg'}
            title={t('NewCompanyLinkModal.NEW_COMPANY_LINK_1')}
            isEdited={isEdited}
            submit={handleSubmit}
            isLoading={loading}
            disableSubmit={!!Object.values(errors).filter(s => s).length}>

            <Row>
                <Col xs={12}>
                    <FormBase row={false} controlId={'linkType'} title={t('NewCompanyLinkModal.LINK_TYPE_1')} className={'pb-2'}>
                        <Form.Check
                            type={"radio"}
                            inline
                            label={t('NewCompanyLinkModal.EXTERNAL_1')}
                            checked={LinkType.EXTERNAL.toString() === updated.linkType}
                            name={"linkType"}
                            id={"external_linkType"}
                            value={LinkType.EXTERNAL}
                            onChange={(e: any) => handleChangeLinkType(e)}
                        />
                        <Form.Check
                            type={"radio"}
                            inline
                            label={t('NewCompanyLinkModal.DOCUMENT_1')}
                            checked={LinkType.DOCUMENT.toString() === updated.linkType}
                            name={"linkType"}
                            id={"document_linkType"}
                            value={LinkType.DOCUMENT}
                            onChange={(e: any) => handleChangeLinkType(e)}
                        />
                    </FormBase>
                </Col>
                <Col xs={12}>
                    <FormBase row={false} controlId={'name'} title={t('NewCompanyLinkModal.NAME_1')} className={'pb-2'}>
                        <GenericInput name={'name'} {...commonProps} />
                    </FormBase>
                </Col>
            </Row>

            <When condition={updated.linkType === LinkType.EXTERNAL.toString()}>
                <Row>
                    <Col xs={12}>
                        <FormBase row={false} controlId={'url'} title={t('NewCompanyLinkModal.URL_1')} className={'pb-2'}>
                            <GenericInput name={'url'} {...commonProps} description={t('NewCompanyLinkModal.FORMAT_PATTERN_1')} />
                        </FormBase>
                    </Col>
                </Row>
            </When>

            <When condition={updated.linkType === LinkType.DOCUMENT.toString()}>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'documentId'} title={t('NewCompanyLinkModal.DOCUMENT_1')} className={'pb-2'}>
                            <div className={styles.templatesDirectoryTreeBorder + ' m-0'}>
                                <DirectoryLite
                                    treeData={data}
                                    expandedFolders={Array.from(expandedFolders)}
                                    handleExpandedFoldersToggle={handleExpandedFoldersToggle}
                                    onClickNode={handleSetSelectedNode}
                                    selectedNode={selectedNode}
                                />
                            </div>
                        </FormBase>
                    </Col>
                </Row>
            </When>
        </ModalForm>
    );
}

export default NewCompanyLinkModal;