import { useTranslation } from "react-i18next";
import { useUnlockDocument } from "../../../api/DocumentApi";
import ModalForm from "../../../components/FormComponents/ModalForm";

const UnlockDocumentModal: React.FC<{
    show: boolean,
    hide: () => void,
    documentId: string
}> = ({
    show,
    hide,
    documentId
}) => {

        const { t } = useTranslation();
        const [unlock, isUnlocking] = useUnlockDocument(documentId);

        return <ModalForm
            show={show}
            hide={hide}
            title={t('UnlockDocumentModal.UNLOCK_DOCUMENT_1')}
            isEdited={() => false}
            submit={() => unlock().then(_ => hide())}
            submitText={t('UnlockDocumentModal.UNLOCK_1')}
            isLoading={isUnlocking}>

            <p>{t('UnlockDocumentModal.CONFIRM_UNLOCK_1')}</p>
        </ModalForm>
    };

export default UnlockDocumentModal;
