import { getBackendOptions, MultiBackend, NodeModel, NodeRender, Tree, TreeMethods } from "@minoru/react-dnd-treeview";
import { useRef } from "react";
import { DndProvider } from "react-dnd";
import { IDocumentNodeModel, IFolderNodeModel } from "../../core/interfaces/directory";
import DirectoryNode from "./Components/DirectoryNode";


interface IDirectoryLite {
    treeData: NodeModel<IFolderNodeModel | IDocumentNodeModel>[];
    expandedFolders: string[];
    onClickNode: (node: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => void;
    selectedNode: NodeModel<IFolderNodeModel | IDocumentNodeModel> | null;
    handleExpandedFoldersToggle: (id: string) => void;
}

const DirectoryLite = (
    {
        treeData,
        expandedFolders,
        onClickNode,
        selectedNode,
        handleExpandedFoldersToggle,
    }: IDirectoryLite) => {
    const ref = useRef<TreeMethods>(null);

    const nodeRender: NodeRender<IFolderNodeModel | IDocumentNodeModel> = (
        node: NodeModel<IFolderNodeModel | IDocumentNodeModel>,
        { depth, isOpen, onToggle }
    ) => (
        <DirectoryNode
            node={node}
            depth={depth}
            isOpen={isOpen}
            isSelected={node === selectedNode}
            onToggle={onToggle}
            onClickNode={onClickNode}
            handleExpandedFoldersToggle={handleExpandedFoldersToggle}
        />
    )

    return <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
            classes={{
                root: "treeRoot400",
                draggingSource: "draggingSource",
                dropTarget: "dropTarget",
                placeholder: "placeholderContainer"
            }}
            tree={treeData}
            ref={ref}
            rootId={0}
            sort={false}
            canDrag={() => false}
            canDrop={() => false}
            onDrop={() => NaN}
            render={nodeRender}
            initialOpen={expandedFolders}
        />
    </DndProvider>
};

export default DirectoryLite;